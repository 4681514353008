import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// change theme colors
const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primaryColor: "#1E1E1D",
        secondaryColor: "#171B1D",
        backgroundColor: "#FBFBFC",
        warning: "#ffc600",
        hoverColor: "#DCE3EB",
        greyColor: "#efefef",
        darkGrey: "#888888",
        lightPopupColor: "#E7EEEA",
        headerColor: "#FBFBFC",
        footerColor: "#F4F4F4",
        primaryActionColor: "#171B1D",
        secondaryActionColor: "#DFFF00",
        headerTextColor: "#171B1D",
        footerTextColor: "#171B1D",
        secondaryTextColor: "#fbfbfb",
        yellowColor: "#DFFF00"
      },
    },
  },
});

export default vuetify;
